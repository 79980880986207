const device = require('react-device-detect')

// const baseApiUrl = "http://0.0.0.0:9002/"
export const baseApiUrl = "https://api-wallet.ovato.com/"
export const secret = "85f8a2f8e2ac250e37c6d8"
export const explorer= "https://polygonscan.com/tx"
export const toastStyle = {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined
}

export const wallet = "dev"
export const paginationdata = 20
export const maintenancepage=false

export const deviceDetect = {
    os: device.osName,
    browsername: device.browserName,
    devicetype: device.deviceType,
    plateform: 'website'
}

export const explorerBaseApiUrl  = "https://api-explorer.ovato.com/"
export const baseUserAuthApiUrl = "https://api-auth.ovato.com/"
// export const baseUserAuthApiUrl = "http://0.0.0.0:9004/"
export const EXPLORER_URL = "https://polygonscan.com/tx"
export const WALLET_URL = "https://api-wallet.ovato.com/"
export const OVATO_URL = "https://ovato.com"
// export const WALLET_URL = "http://0.0.0.0:9002/"
